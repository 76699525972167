<template>
	<div>
		<head-top></head-top>
		<el-input size="small" v-model="notice.title" placeholder="公告标题"></el-input>
		<div class="edit_container" style="margin-top:10px ;">
			<quill-editor
				v-model="notice"
				ref="myQuillEditor"
				@blur="onEditorBlur($event)"
				@focus="onEditorFocus($event)"
				@ready="onEditorReady($event)"
				@change="onEditorChange($event)"
				:options="editorOption"
			></quill-editor>
			<el-upload
				:action="idcardFront"
				:limit="1"
				list-type="picture-card"
				:file-list="fileList"
				:auto-upload="false"
				ref="upload"
				accept="image/png,image/jpg,image/jpeg"
				:before-upload="beforeAvatarUpload"
				:on-change="getFront"
				:data="idcardFront"
				:http-request="httprequest"
				:class="{ hide: hideUpload }"
				:before-remove="beformRemove"
				:on-remove="handleRemove"
				style="margin: 20px 0;"
			>
				<el-button size="small" type="primary">点击上传</el-button>
			</el-upload>
		</div>
		<div class="submit_btn" style="margin: 50px 20px;width: 100%;"><el-button size="small" type="primary" @click="submit">提交</el-button></div>
	</div>
</template>
<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { add } from '../api/notice';
import { quillEditor } from 'vue-quill-editor/src';
export default {
	props: {
		/*编辑器的内容*/
		value: {
			type: String
		}
	},
	data() {
		return {
			fileList: [],
			hideUpload: false,
			domain: 'https://upload-z2.qiniup.com',
			qiniuaddr: ' r8s8zg6k5.hn-bkt.clouddn.com',
			limitCount: 1,
			
			notice: {},
			editorOption: {
				theme: 'snow', // or 'bubble'
				placeholder: '请输入...',
				modules: {
					toolbar: [
						['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
						['blockquote', 'code-block'], //引用，代码块
						[{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
						[{ list: 'ordered' }, { list: 'bullet' }], //列表
						[{ script: 'sub' }, { script: 'super' }], // 上下标
						[{ indent: '-1' }, { indent: '+1' }], // 缩进
						[{ direction: 'rtl' }], // 文本方向
						[{ size: ['small', false, 'large', 'huge'] }], // 字体大小
						[{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
						// [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
						[{ font: [] }], //字体
						[{ align: [] }], //对齐方式
						['clean'] //清除字体样式
						// ['image','video']    //上传图片、上传视频
					]
				}
			}
		};
	},
	components: {
		quillEditor
	},
	computed: {
		editor() {
			return this.$refs.myQuillEditor.quill;
		}
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		onEditorBlur(quill) {
			this.notice.content = quill.root.innerHTML;
		},
		onEditorFocus(quill) {
			// console.log('editor focus!', quill)
		},
		onEditorReady(quill) {
			// console.log('editor ready!', quill)
		},
		onEditorChange(quill) {
			this.notice.content = quill.html;
		},
		submit() {
			add(this.notice).then(res => {
				if (res.code == 200) {
					this.$message.success('公告添加成功');
					this.$router.push('/notice');
				} else {
					this.$message.error(res.message);
				}
			});
		},
		// 传身份证正面
		getFront(file, fileList) {
			this.hideUpload = fileList.length >= this.limitCount;
			this.getBase64(file.raw).then(res => {
				this.notice.url=res;
			});
		},
		// 转图片转vase64
		getBase64(file) {
			return new Promise(function(resolve, reject) {
				let reader = new FileReader();
				let imgResult = '';
				reader.readAsDataURL(file);
				reader.onload = function() {
					imgResult = reader.result;
				};
				reader.onerror = function(error) {
					reject(error);
				};
				reader.onloadend = function() {
					resolve(imgResult);
				};
			});
		},
		httprequest(req) {
			this.config = {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			};
			this.keyname = 'lytton' + new Date() + Math.floor(Math.random() * 100) + '.';
			uptoken().then(res => {
				this.token1 = res;
				this.formdata = new FormData();
				this.formdata.append('file', req.file);
				this.formdata.append('toke', req.data);
				this.formdata.append('key', this.keyname);
				// uptoken1(this.domain, this.formdata, this.config).then(res => {
				// 	this.imageUrl = 'http://' + this.qiniuaddr + '/' + res.data.key;
				// });
			});
		},
		// 上传照片后隐藏上传按钮
		beformRemove1(fileList) {
			this.hideUpload = fileList.length >= this.limitCount;
		},
		// 删除照片
		handleRemove1() {
			this.form.businessLicense = '';
		},
	}
};
</script>
<style>
	.ql-container.ql-snow{background-color: #fff;}
</style>