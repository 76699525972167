import request from '../utils/request';
import qs from "qs";

// 查询可回收数据
export const recycleCustomers = param => {
	return request.post('/api/customer/recycleCustomers', qs.stringify(param))
};
//根据员工ID查询二级文件
export const findNormalFileByUserId = normalId => {
	return request.get('/api/customer/findNormalFileByUserId?normalId=' + normalId)
};
//根据部门ID查询员工
export const getUserByDepartmentId = departmentId => {
	return request.get('/api/user/getUserByDepartmentId?departmentId=' + departmentId)
};
// 导出回收客户
export const downloadCustomer = param => {
	return request.post('/api/customer/downloadCustomer', qs.stringify(param), {
		responseType: 'blob'
	})
};
// 分配回收客户
export const allotCustomers = param => {
	return request.post('/api/customer/allotCustomers', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};
// 回收客户
export const recycleData = param => {
	return request.post('/api/customer/recycle', qs.stringify(param))
};
// 查询回收文件
export const recycieFile = param => {
	return request.post('/api/customer/recycleFile', qs.stringify(param))
};
//删除 
export const deleteRecycleFile = param => {
	return request.post('/api/customer/deleteRecycleFile', qs.stringify(param))
};


// 购买月份
export const buyMonths = param => {
	return request.post('/api/admin/buyMonths', qs.stringify(param))
};
//查询剩余有效期
export const getAdminMonths = param => {
	return request.get('/api/admin/getAdminMonths')
};

// 查询admin有效期使用记录
export const getAdminSerial = param => {
	return request.post('/api/admin/getAdminSerial', qs.stringify(param))
};
// 查询支付记录
export const queryPaymentRecords = param => {
	return request.post('/api/admin/queryPaymentRecords', qs.stringify(param))
};
// 添加月份
export const addAdminMonths = param => {
	return request.post('/api/admin/addAdminMonths', qs.stringify(param))
};
// 查询订单
export const queryPayStatus = param => {
	return request.post('/api/admin/queryPayStatus', qs.stringify(param))
};
