<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-model="query.cardType" filterable clearable placeholder="品牌" class="handle-select mr10">
					<el-option v-for="item in cardTypeData" :key="item.value" :label="item.value" :value="item.label"></el-option>
				</el-select>
				<el-select size="small" @change="provinceListChange" v-model="query.province" filterable clearable placeholder="省份" class="handle-select mr10">
					<el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.city" filterable clearable placeholder="地级市" class="handle-select mr10">
					<el-option v-for="item in cityList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="电话号码" class="handle-input mr10"></el-input>
				<el-button size="small" type="primary" icon="el-icon-search" class="m_t_20" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" icon="el-icon-delete" class="m_t_20" @click="handleDelete">批量删除</el-button>
				<el-button size="small" type="primary" icon="el-icon-delete" class="m_t_20" @click="fileDeletes">文件删除</el-button>
				<a :href="`${path}numberPool.xlsx`" class="down m_t_20 m_l_10" download="号码池模板.xlsx">
					<i class="el-icon-download"></i>
					下载模板
				</a>
				<el-button size="small" type="primary" icon="el-icon-upload" class="m_t_20 m_l_10" @click="importForm">导入</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="cardType" label="品牌" align="center"></el-table-column>
				<el-table-column prop="attribution" label="归属地" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="电话号码" align="center"></el-table-column>
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-delete" class="red" @click="handleDelete2(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 1000, 2000, 5000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>

		<el-dialog title="编辑" v-model="editVisible" width="500px" @close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="代理商" prop="agentId" v-if="isAdmin">
					<el-select size="small" v-model="form.agentId" @change="agentChange" filterable clearable placeholder="请选择代理商" :disabled="userDis" style="width: 300px">
						<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="企业" prop="companyId">
					<el-select
						size="small"
						v-model="form.companyId"
						filterable
						clearable
						placeholder="请选择企业名称"
						@change="companyIdChange"
						:disabled="userDis"
						style="width: 300px"
					>
						<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="部门" prop="departmentId">
					<el-select size="small" v-model="form.departmentId" filterable clearable placeholder="请选择部门" style="width: 300px;">
						<el-option v-for="item in departments" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="操作" prop="addOrDelete">
					<el-select size="small" v-model="form.addOrDelete" placeholder="请选择" :disabled="userDis" style="width: 300px">
						<el-option v-for="item in addOrDeleteList" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span class="dialog-footer myRight">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" type="primary" @click="updateAgent('form')">确定</el-button>
			</span>
		</el-dialog>

		<div class="customer">
			<el-dialog title="文件导入" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload
					ref="upload"
					action="/api/numberPool/upload"
					:headers="token"
					:limit="1"
					:on-success="uploadSuccess"
					:on-preview="handlePreview"
					:on-remove="handleRemove"
					:auto-upload="false"
				>
					<el-button size="small" type="primary">选择文件</el-button>
					<div class="el-upload__tip">只能导入excel文件</div>
				</el-upload>
				<el-button size="small" class="btn" type="success" v-loading.fullscreen.lock="loading" @click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>
		<div class="customer">
			<el-dialog title="文件删除" v-model="upload2" width="500px" @close="closeUpload2()" :close-on-click-modal="false">
				<el-upload
					ref="upload2"
					action="/api/numberPool/deleteUpload"
					:headers="token"
					:limit="1"
					:on-success="uploadSuccess2"
					:on-preview="handlePreview2"
					:on-remove="handleRemove2"
					:auto-upload="false"
					:data="uploadData"
				>
					<el-button size="small" type="primary">选择文件</el-button>
					<div class="el-upload__tip">只能删除excel文件</div>
				</el-upload>
				<el-button size="small" class="btn" type="success" v-loading.fullscreen.lock="loading" @click="submitUpload2">确定删除</el-button>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { list, delAgent, updateAgent, fetchAgentById, fetchAgent } from '../api/agentIndex.js';
import { fetchDefaultCompanies, fetchCompanyByAgentId, getCity, getProvince, numberList, numberPoolUpload, numberPoolDelete, numberPoolEdit } from '../api/companyIndex.js';
import { getCardType } from '../api/cardType.js';
import { fetchDepartment } from '../api/index.js';
export default {
	name: 'agent',
	data() {
		return {
			query: {
				agentId: '',
				companyId: '',
				cardType: '',
				distribution: 0,
				province: '',
				city: '',
				phoneNumber: '',
				pageIndex: 1,
				pageSize: 100
			},
			distributionList: [{ label: '全部', distribution: 0 }, { label: '已分配', distribution: 1 }, { label: '未分配', distribution: 2 }],
			addOrDeleteList: [{ label: '分配', value: 1 }, { label: '回收', value: 2 }],
			tableData: [],
			agents: [],
			cardTypeData: [],
			multipleSelection: [],
			departments: [],
			delList: [],
			editVisible: false,
			pageTotal: 0,
			path: process.env.BASE_URL,
			selectForm: {
				region: 0
			},
			form: {
				companyId: '',
				agentId: '',
				departmentId: '',
				addOrDelete: 1
			},
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			rules: {
				name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
				code: [{ required: true, message: '请输入企业代码', trigger: 'blur' }],
				ownerName: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
				ownerPhone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
			},
			upload: false,
			upload2: false,
			isAdmin: false,
			str: '', //多选
			uploadData: {
				currentUserId: localStorage.getItem('user')
			}
		};
	},
	created() {
		let role = localStorage.getItem('ms_role');
		if (role === 'normal') {
			this.isNormal = false;
		}
		if (role === 'admin') {
			this.isAdmin = true;
		}
		if (role === 'agent') {
			this.isAgent = true;
			this.fetchDefaultCompanies();
		}
		if (role === 'agent' || role === 'admin') {
			this.isAgent = true;
		}
		this.init();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			this.numberLists();
			this.fetchAgents();
			this.getCardTypes();
			this.getProvinces();
			this.fetchDepartments('');
		},
		numberLists() {
			let data = {
				distribution: this.query.distribution,
				cardsId: this.query.cardType,
				province: this.query.province,
				city: this.query.city,
				agentId: this.query.agentId,
				companyId: this.query.companyId,
				phoneNumber: this.query.phoneNumber.replaceAll(' ', ''),
				pageSize: this.query.pageSize,
				pageIndex: this.query.pageIndex,
				currentUserId: localStorage.getItem('user')
			};
			numberList(data).then(res => {
				this.tableData = res.data.list;
				this.pageTotal = res.data.total;
			});
		},
		//代理商信息
		fetchAgents() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
				// this.query.agentId = this.agents[0].value;
				// this.fetchCompanyByAgentIds(this.query.agentId);
			});
		},
		//部门
		fetchDepartments(companyId) {
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
		},
		//切换企业
		companyIdChange(companyId) {
			this.fetchDepartments(companyId);
		},
		//切换代理商
		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			if (agentId) {
				let data = {
					agentId: agentId
				};
				fetchCompanyByAgentId(data).then(res => {
					this.companys = res.data;
				});
			}
		}, //切换城市
		provinceListChange(e) {
			let data = {
				code: e
			};
			getCity(data).then(res => {
				this.cityList = res.data;
			});
			console.log(e, '反复看反馈');
		},
		//公司
		fetchDefaultCompanies() {
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
			});
		},
		//品牌
		getCardTypes() {
			getCardType().then(res => {
				if (res.code === 200) {
					this.cardTypeData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		//省份
		getProvinces() {
			getProvince().then(res => {
				if (res.code === 200) {
					this.provinceList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		//导入
		importForm() {
			this.upload = true;
		},
		//导入上传
		uploadSuccess(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload.clearFiles();
				this.upload = false;
			}, 2000);
			if (success.code === 200) {
				this.$message.success('上传成功');
				this.init();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		closeUpload() {
			this.$refs.upload.clearFiles();
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		fileDeletes() {
			this.upload2 = true;
		},
		submitUpload2() {
			if (this.$refs.upload2.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload2.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		uploadSuccess2(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload2.clearFiles();
				this.upload2 = false;
			}, 2000);
			if (success.code === 200) {
				this.$message.success('删除成功');
				this.init();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		closeUpload2() {
			this.$refs.upload2.clearFiles();
		},
		handleRemove2(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview2(file) {
			console.log(file);
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		showForm() {
			this.editVisible = true;
			this.ids = this.str;
		},

		// 编辑操作
		handleEdit(index, id) {
			// this.idx = index;
			this.ids = id;
			this.editVisible = true;

			// 获取代理详情
			// fetchAgentById(id).then(res => {
			// 	this.form = res.data;
			// });
		},

		updateAgent(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					this.form.ids = this.ids;
					this.form.userId = localStorage.getItem('user');
					numberPoolEdit(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('修改成功');
							this.numberLists();
						} else {
							this.$message.error(res.message);
						}
						this.editVisible = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.numberLists();
		},
		// 多选操作
		handleSelectionChange(val) {
			this.delList = [];
			this.str = [];
			this.multipleSelection = val;
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.str += this.multipleSelection[i].id + ',';
			}
		},
		// 删除操作
		handleDelete() {
			if (this.str === '') {
				this.$message.error(`请选择要删除的数据`);
				return;
			} else {
				let param = {
					ids: this.str,
					currentUserId: localStorage.getItem('user')
				};
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
					type: 'warning'
				})
					.then(() => {
						numberPoolDelete(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.numberLists();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
				this.multipleSelection = [];
			}
		},
		// 删除操作
		handleDelete2(index, id) {
			let param = {
				ids: id,
				currentUserId: localStorage.getItem('user')
			};
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					numberPoolDelete(param).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.numberLists();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.init();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.init();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			this.editVisible = false;
			(this.form = {
				companyId: '',
				agentId: '',
				addOrDelete: 1
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 14px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
	width: 88px;
}

.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
<style type="text/css">
.customer .el-upload {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100% !important;
	line-height: 50px !important;
}
.customer .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
