<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="电话号码" class="handle-input mr10"></el-input>
				<el-select size="small" v-model="query.brand" filterable clearable placeholder="品牌" class="handle-select mr10">
					<el-option v-for="item in cardTypeData" :key="item.value" :label="item.value" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" icon="el-icon-search" style="margin-top: 16px;" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" icon="el-icon-upload" style="margin-top: 16px;" @click="importForm">导入</el-button>
				<el-button size="small" type="primary" icon="el-icon-delete" style="margin-top: 16px;" @click="handleDelete">批量删除</el-button>
				<a :href="`${path}shutdownRecord.xlsx`" class="down" style="margin:0 10px;" download="停机记录模板.xlsx">
					<i class="el-icon-download"></i>
					下载模板
				</a>
				<el-button size="small" type="primary" icon="el-icon-upload2  el-icon--right" @click="exportData">导出</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="day" label="日期" width="120" align="center"></el-table-column>
				<el-table-column prop="companyName" label="公司名称" width="300" align="center"></el-table-column>
				<el-table-column prop="brand" label="品牌" width="140" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="电话号码" width="140" align="center"></el-table-column>
				<el-table-column prop="appCall" label="APP拨号次数" width="120" align="center"></el-table-column>
				<el-table-column prop="snailsCall" label="BSS主叫次数(蜗牛)" width="160" align="center"></el-table-column>
				<el-table-column prop="difference" label="差值" width="120" align="center"></el-table-column>
				<el-table-column prop="remark" label="备注" align="center"></el-table-column>
				<el-table-column label="操作" width="180" align="center" fixed="right">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" class="red" @click="handleDelete2(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 1000, 2000, 5000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>

		<div class="customer">
			<el-dialog title="文件导入" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload
					ref="upload"
					action="/api/numberPool/downRecordUpload"
					:headers="token"
					:limit="1"
					:on-success="uploadSuccess"
					:on-preview="handlePreview"
					:on-remove="handleRemove"
					:auto-upload="false"
					:data="uploadData"
				>
					<el-button size="small" type="primary">选择文件</el-button>
					<div class="el-upload__tip">只能导入excel文件</div>
				</el-upload>
				<el-button size="small" class="btn" type="success" v-loading.fullscreen.lock="loading" @click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>
		<div class="customer">
			<el-dialog title="编辑" v-model="editVisible" width="500px" :close-on-click-modal="false">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px">
					<el-form-item label="备注"><el-input size="small" type="textarea" v-model="form.remark"></el-input></el-form-item>

					<el-form-item>
						<el-button size="small" style="margin-left: 50%;" @click="editVisible = false">取消</el-button>
						<el-button size="small" type="primary" @click="edit('form')">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { findDownRecord, downRecordDelete, getupdateDownRecord, downloadDownRecord } from '../api/reportNumber.js';
import { getCardType } from '../api/cardType.js';
export default {
	name: 'agent',
	data() {
		return {
			query: {
				phoneNumber: '',
				brand: '',
				pageIndex: 1,
				pageSize: 100
			},
			cardTypeData: [],
			tableData: [],
			multipleSelection: [],
			delList: [],
			pageTotal: 0,
			path: process.env.BASE_URL,
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			upload: false,
			isAdmin: false,
			editVisible: false,
			str: '', //多选
			form: {
				remark: ''
			},
			uploadData: {
				currentUserId: localStorage.getItem('user')
			}
		};
	},
	created() {
		this.getfindDownRecord();
		this.getCardTypes();
		let role = localStorage.getItem('ms_role');

		if (role === 'normal') {
			this.isNormal = false;
		}
		if (role === 'admin') {
			this.isAdmin = true;
		}
		if (role === 'agent') {
			this.isAgent = true;
		}
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getfindDownRecord() {
			let data = {
				phoneNumber: this.query.phoneNumber.replaceAll(' ', ''),
				brand: this.query.brand,
				pageSize: this.query.pageSize,
				pageIndex: this.query.pageIndex,
				currentUserId: localStorage.getItem('user')
			};
			findDownRecord(data).then(res => {
				this.tableData = res.data.list;
				this.pageTotal = res.data.total;
			});
		},
		//品牌
		getCardTypes() {
			getCardType().then(res => {
				if (res.code === 200) {
					this.cardTypeData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},

		//导入
		importForm() {
			this.upload = true;
		},
		//导入上传
		uploadSuccess(success) {
			setTimeout(() => {
				this.loading = false;
				this.$refs.upload.clearFiles();
				this.upload = false;
			}, 2000);
			if (success.code === 200) {
				this.$message.success('上传成功');
				this.getfindDownRecord();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		closeUpload() {
			this.$refs.upload.clearFiles();
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
			} else {
				this.$message.error('请选择上传文件');
			}
		},

		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},

		// 编辑操作
		handleEdit(row) {
			this.form.remark = row.remark;
			this.form.id = row.id;
			this.editVisible = true;
		},

		edit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					getupdateDownRecord(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('修改成功');
							this.getfindDownRecord();
						} else {
							this.$message.error(res.message);
						}
						this.editVisible = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getfindDownRecord();
		},
		// 多选操作
		handleSelectionChange(val) {
			this.delList = [];
			this.str = [];
			this.multipleSelection = val;
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				this.str += this.multipleSelection[i].id + ',';
			}
		},
		exportData() {
			this.loading = true;
			downloadDownRecord(this.str).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let fileName = '停机记录' + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
				this.loading = false;
			});
		},
		// 删除操作
		handleDelete() {
			if (this.str) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
					type: 'warning'
				})
					.then(() => {
						let param = {
							ids: this.str,
							currentUserId: localStorage.getItem('user')
						};
						downRecordDelete(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getfindDownRecord();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
				this.multipleSelection = [];
			} else {
				this.$message.error('请选择删除的行');
			}
		},
		// 删除操作
		handleDelete2(index, id) {
			this.str = id;
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let param = {
						ids: this.str,
						currentUserId: localStorage.getItem('user')
					};
					downRecordDelete(param).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getfindDownRecord();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
			this.multipleSelection = [];
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getfindDownRecord();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getfindDownRecord();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			this.editVisible = false;
			this.getfindDownRecord();
			(this.form = {
				remark: ''
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 14px;
	border-radius: 4px;
	margin-left: 10px;
	display: inline-block;
	width: 88px;
}

.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.customer .el-upload {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100% !important;
	line-height: 50px !important;
}
.customer .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
<style>
.customer .el-upload {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}
.customer .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
