<template>
	<div>
		<div class="container">
			<div class="handle-box">
				代理:
				<el-select size="small" v-model="query.agentId" filterable clearable placeholder="代理" class="handle-select mr10">
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>

				<el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="agentId" label="代理id" align="center"></el-table-column>
				<el-table-column prop="name" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="code" label="企业代码" align="center"></el-table-column>
				<el-table-column prop="validCallTime" label="分配时长(月)" align="center" width="120">
					<template #default="scope">
						<span>{{ scope.row.validCallTime.toFixed(2) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="date" label="分配时间" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="query.pageIndex"
					:page-size="query.pageSize"
					:total="pageTotal"
					@current-change="handlePageChange"
					:row-style="{ height: '20px' }"
					:cell-style="{ padding: '6px' }"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { list } from '../api/serialIndex.js';
import { fetchAgent } from '../api/agentIndex.js';

export default {
	name: 'serial',
	data() {
		return {
			query: {
				agentId: '',
				pageIndex: 1,
				pageSize: 30
			},
			tableData: [],
			agents: [],
			multipleSelection: [],
			delList: [],
			editVisible: false,
			addVisible: false,
			addButton: false,
			updateButton: false,
			pageTotal: 0,
			selectForm: {
				region: 0
			},
			form: {
				name: '',
				code: '',
				ownerName: '',
				ownerPhone: '',
				validCallTime: 0,
				enable: false
			},
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') }
		};
	},
	created() {
		this.init();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
		},
		getData() {
			this.query.currentUserId = localStorage.getItem('user');
			list(this.query).then(res => {
				if (res.code === 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		showForm() {
			this.addVisible = true;
			this.addButton = true;
			this.updateButton = false;
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>

<style scoped>
.sale-table .avatar-uploader .el-upload--text {
	font-size: 66px !important;
}
.hide .el-upload--picture-card {
	display: none;
}

</style>