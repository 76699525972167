<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.companyName" clearable placeholder="企业名称" class="handle-input mr10" style="width: 300px"></el-input>
				<el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" icon="el-icon-delete  el-icon--right" class="handle-del" @click="batchDel">批量删除</el-button>
				<el-button size="small" type="primary" icon="el-icon-add  el-icon--right" class="handle-add" @click="showForm">添加黑名单库</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange"
				:row-class-name="tableRowClassName"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="companyId" label="企业id" align="center"></el-table-column>
				<el-table-column prop="companyName" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="创建时间" align="center"></el-table-column>

				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<!-- <el-button size="small" type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row.id)">编辑</el-button> -->
						<el-button size="small" type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row.companyId)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="query.pageIndex"
					:page-size="query.pageSize"
					:total="pageTotal"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>

		<el-dialog title="添加黑名单总库" v-model="addVisible" width="500px" @close="closeDialog('form2')" :close-on-click-modal="false">
			<el-form :model="form2" :rules="rules" ref="form2" label-width="100px">
				<el-form-item label="企业名称" prop="companyId">
					<el-input size="small" type="textarea" placeholder="一个企业一行，最多加30个企业" rows="5" v-model="form2.companyId"></el-input>
				</el-form-item>
				<!-- <view class="tip" style="margin-left: 100px;color: red;font-size: 12px;"></view> -->
			</el-form>
			<div style="display: flex;flex-direction: row;justify-content: flex-end;">
				<el-button size="small" @click="closeDialog('form2')" >取消</el-button>
				<el-button size="small" type="primary" @click="addAgent('form2')" :loading="loading">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { list, delAgent, addAgent, updateAgent, fetchAgentById, fetchAgent, addCompany, getCompany, deleteCompany } from '../api/agentIndex.js';
export default {
	name: 'agent',
	data() {
		return {
			query: {
				agentId: '',
				companyName: '',
				userId: '',
				pageIndex: 1,
				pageSize: 30
			},
			tableData: [],
			agents: [],
			multipleSelection: [],
			delList: [],
			editVisible: false,
			addVisible: false,
			addButton: false,

			pageTotal: 0,
			selectForm: {
				region: 0
			},
			form: {
				name: '',
				code: '',
				ownerName: '',
				ownerPhone: '',
				validCallTime: 0,
				enable: false
			},
			form2: {
				companyId: ''
			},
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			rules: {
				companyId: [{ required: true, message: '请输入企业ID', trigger: 'blur' }]
			}
		};
	},
	created() {
		this.init();
		this.getData();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
		},
		getData() {
			let data = {
				companyName: this.query.companyName,
				userId: localStorage.getItem('user'),
				pageSize: this.query.pageSize,
				pageIndex: this.query.pageIndex
			};
			getCompany(data).then(res => {
				if (res.code === 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		showForm() {
			this.addVisible = true;
			this.addButton = true;
		},
		addAgent(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					this.form2.userId = localStorage.getItem('user');
					addCompany(this.form2).then(res => {
						if (res.code === 200) {
							this.$message.success('添加成功');
							this.addVisible = false;
							this.loading = false;
							this.getData();
						} else {
							this.$message.error('添加失败');
							this.addVisible = false;
							this.loading = false;
						}
					});
				}
			});
		},
		// 编辑操作
		handleEdit(index, id) {
			this.addVisible = true;
			this.idx = index;
			this.addButton = false;
			this.updateButton = true;
			// 获取详情
			fetchAgentById(id).then(res => {
				this.form = res.data;
			});
		},
		updateAgent(formName) {
			console.log(formName, 'formNameformNameformName');
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					this.form.userId = localStorage.getItem('user');
					updateAgent(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('修改成功');
							this.loading = false;
							this.getData();
						} else {
							this.$message.error(res.message);
							this.loading = false;
						}
						this.addVisible = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		/* 批量删除 */
		batchDel() {
			let str = '';
			this.delList = this.delList.concat(this.multipleSelection);
			for (let i = 0; i < this.multipleSelection.length; i++) {
				str += this.multipleSelection[i].companyId + ',';
			}
			if (str === '') {
				this.$message.error(`请选择要删除的数据`);
				return;
			} else {
				let param = {
					companyId: str
				};
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
					type: 'warning'
				})
					.then(() => {
						deleteCompany(param).then(res => {
							if (res.code == 200) {
								this.$message.success('批量删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});

				this.multipleSelection = [];
			}
		},
		// 多选操作
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		// 删除操作
		handleDelete(index, companyId) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = {
						companyId: companyId
					};
					deleteCompany(data).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			this.addVisible = false;
			(this.form = {
				name: '',
				code: '',
				ownerName: '',
				ownerPhone: ''
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 50px;
	display: flex;
	flex-direction: row;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
