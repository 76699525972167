<template>
	<div class="qcontent">
		<el-row :gutter="20" type="flex" class="row-bg" justify="space-between">
			<el-col :span="7" style="background-color: #fff;border-radius: 8px;position: fixed;left: 13.3%;min-width: 21%;max-width: 21%;">
				<div class="tree_box">
					<div style="top:145px;z-index: 999;height: 40px;background: #fff;padding-top: 10px;border-radius: 8px;">
						<el-input size="small" v-model="filterText" placeholder="请输入公司名称" style="width: 90%;margin: 0 5%;"></el-input>
					</div>
					<el-tree
						:data="treeData"
						:highlight-current="true"
						:props="defaultProps"
						@node-click="handleNodeClick"
						style="height: 75vh;overflow-y: auto;padding: 10px;"
						accordion
						highlight-current
						ref="tree"
						node-key="vua"
						:disabled="!loading"
						:filter-node-method="filterNode"
					>
						<template #default="{ node, data }">
							<el-tag v-if="data.quality" style="height: 22px;line-height: 22px;position: absolute;left: 10px;padding:0 3px;margin-right: 3px;">检</el-tag>
							<el-tag v-if="data.classification" type="success" style="height: 22px;line-height: 22px;position: absolute;left: 34px;padding:0 5px;">
								{{ data.classification }}
							</el-tag>
							<span v-if="data.quality" style="margin-left: 20px;">{{ data.label }}</span>
							<span v-else style="margin-left: 20px;">{{ data.label }}</span>
						</template>
					</el-tree>
				</div>
			</el-col>
			<el-col :span="17" style="padding: 20px;background-color: #fff;border-radius: 8px;position: fixed;right: 0;width: 65%;">
				<div class="" style="margin-bottom: 12px;">
					<el-form style="display: flex;flex-direction: row;">
						<el-form-item label="通话时期:" label-width="120px">
							<el-date-picker
								v-model="timeArr"
								type="daterange"
								style="width: 260px;margin-right: 10px;"
								unlink-panels
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="getTime()"
								size="small"
							></el-date-picker>
						</el-form-item>
						<el-form-item label="通话时长:" label-width="120px">
							<el-select
								size="small"
								style="width: 140px;margin-right: 10px;"
								v-model="time"
								@change="getmaximumRecordList"
								filterable
								clearable
								placeholder="请选择录音时长"
								class="handle-select mr10"
							>
								<el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item><el-button size="small" :class="[theme]" type="primary" @click="getmaximumRecordList">搜索</el-button></el-form-item>
					</el-form>
				</div>
				<div class="tab_box">
					<el-table
						:data="tableData"
						border
						class="table"
						ref="multipleTable"
						header-cell-class-name="table-header"
						:cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }"
						:header-cell-style="{ 'text-align': 'center' }"
						v-loading="loading"
					>
						<el-table-column prop="phoneNumber" label="客户手机号" align="center" width="115"></el-table-column>
						<el-table-column prop="userPhone" label="员工号码" align="center" width="115"></el-table-column>
						<el-table-column prop="callDate" label="拨打时间" align="center" width="155"></el-table-column>
						<el-table-column prop="callDuration" label="通话时长(s)" align="center" width="100"></el-table-column>
						<el-table-column prop="recordPath" label="录音" align="center" width="550">
							<template #default="scope">
								<div style="display: flex;flex-direction: row;">
									<audio-player ref="audio" class="audio-box" :fileurl="scope.row.recordPath" :row="scope.row"></audio-player>
									<!-- <el-button size="small" type="primary" :class="[theme]" @click="getAudio(scope.row)">录音</el-button> -->
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="" label="操作" align="center" width="200" fixed="right">
							<template #default="scope">
								<div v-if="scope.row.btnShow">
									<el-button size="small" type="primary" :class="[theme]" @click="getriskshow(scope.row)">标记风险</el-button>
									<el-button size="small" type="primary" :class="[theme]" @click="getriskRecord2(scope.row)">通话正常</el-button>
								</div>
								<div v-else>
									<i class="el-icon-success zj"><span>已质检</span></i>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<div>
						<el-image v-for="item in srcList" :src="item" class="avatar" style="width: 100px;height: 100px;" fit="contain" :preview-src-list="srcList"></el-image>
						<!-- <el-upload class="avatar-uploader" action="" :show-file-list="false" disabled>
							<el-image v-if="item" :src="item" class="avatar"  style="width: 100px;height: 100px;" :src="item" fit="contain" :preview-src-list="srcList"></el-image>
							<img v-if="item" :src="item"  style="width: 100px;height: 100px;" @click="handlePictureCardPreview(item)" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload> -->
					</div>
				</div>
			</el-col>
		</el-row>
		<!-- 	<el-dialog title="录音详情" v-model="audioVisible" width="550px" @close="closeDialog()" :close-on-click-modal="false">

			<audio-player ref="audio" class="audio-box" :audiofileurl="customerDetails.recordPath" :audiorow="customerDetails"></audio-player>
			<div style="display: flex;flex-direction: row;justify-content: end;margin-top: 20px;">
				<el-button size="small" type="primary" :class="[theme]" @click="audioVisible = false">确认</el-button>
			</div>
		</el-dialog> -->
		<el-dialog v-model="addVisible"><!-- <img style="width: 100%;height: 800px;" :src="dialogImageUrl" alt="" /> --></el-dialog>
		<el-dialog title="标记风险" v-model="riskVisible" width="550px" @close="closeDialog()" :close-on-click-modal="false">
			<el-form :model="riskForm" :rules="rules" ref="riskForm" label-width="100px">
				<el-form-item label="风险原因:" prop="label">
					<el-radio-group v-model="riskForm.label">
						<el-radio v-for="item in riskList" :key="item.value" :label="item.label" :value="item.value" style="margin: 12px 6px;"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注:" prop="remark"><el-input size="small" v-model="riskForm.remark" placeholder="请输入备注"></el-input></el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getriskRecord">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { fetchDefaultCompanies } from '../api/companyIndex';
import { fetchDepartment, fetchDefaultUsers, QualityCompany, maximumRecordList, riskRecord, completeToday } from '../api/index.js';
import AudioPlayer from '../components/audioPlayer.vue';
import { findByRecordId } from '../api/recordIndex';
export default {
	components: {
		AudioPlayer
	},
	data() {
		return {
			theme: localStorage.getItem('theme'),
			theme3: localStorage.getItem('theme3'),
			treeData: [],
			tableData: [],
			defaultProps: {
				children: 'children',
				label: 'label'
			},
			role: '',
			filterText: '',
			imgUrl: '',
			audioVisible: false,
			customerDetails: '',
			loading: false,
			srcList: [],
			url: 'http://',
			timeList: [
				{
					label: '大于10秒',
					value: '10'
				},
				{
					label: '大于30秒',
					value: '30'
				},
				{
					label: '大于60秒',
					value: '60'
				}
			],
			time: '10',
			timeArr: [],
			riskVisible: false,
			riskForm: {
				label: '其他',
				remark: ''
			},
			riskList: [
				{
					label: '诈骗',
					value: '诈骗'
				},
				{
					label: '辱骂',
					value: '辱骂'
				},
				{
					label: '违规',
					value: '违规'
				},
				{
					label: '报备话术不一致',
					value: '报备话术不一致'
				},
				{
					label: '未获取到录音',
					value: '未获取到录音'
				},
				{
					label: '开启指定录音',
					value: '开启指定录音'
				},
				{
					label: '有投诉风险',
					value: '有投诉风险'
				},
				{
					label: '其他',
					value: '其他'
				}
			],
			dialogImageUrl: '',
			addVisible: false,
			btnShow: true,
			intentionId: ''
		};
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val);
		}
	},
	created() {
		this.role = localStorage.getItem('ms_role');
		this.getTime();
		this.getQualityCompany();
	},
	methods: {
		getAudio(row) {
			// this.customerDetails = row;
			let data = {
				id: row.id,
				currentUserId: localStorage.getItem('user')
			};
			findByRecordId(data).then(res => {
				this.customerDetails = res.data;
				this.audioVisible = true;
			});
		},
		bigImg(url) {
			this.srcList.push(url);
		},

		getTime() {
			let startTime = new Date(new Date());
			let endTime = new Date();
			this.timeArr.push(startTime);
			this.timeArr.push(endTime);
		},
		toDateTimeStr(date) {
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}

			return y + '-' + m + '-' + d;
		},
		filterNode(value, data) {
			if (!value) return true;
			return data.label.indexOf(value) !== -1;
		},

		handleNodeClick(e) {
			console.log(this.loading, '我是什么');
			setTimeout(() => {
				this.companyId = e.vua;
				this.getmaximumRecordList();
			}, 500);
		},
		getcompleteToday() {
			let data = {
				companyId: this.companyId
			};
			completeToday(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.data);
					this.getmaximumRecordList();
				} else {
					this.$message.error('请选择企业');
				}
			});
		},
		getQualityCompany() {
			QualityCompany(localStorage.getItem('user')).then(res => {
				this.treeData = res.data;
				this.companyId = this.treeData[0].vua;
				this.getmaximumRecordList();
			});
		},
		getmaximumRecordList() {
			this.tableData = [];
			this.srcList = [];
			let data = {
				companyId: this.companyId,
				callDuration: this.time,
				startTime: this.toDateTimeStr(this.timeArr[0]),
				endTime: this.toDateTimeStr(this.timeArr[1])
			};
			maximumRecordList(data).then(res => {
				this.loading = false;
				if (res.code == 200) {
					setTimeout(() => {
						res.data.forEach(item => {
							item.btnShow = true;
							this.tableData.push(item);
						});
						res.customData.forEach(item => {
							this.srcList.push(this.url + item);
						});
						this.customData = res.customData;
						console.log(this.tableData, '我是数据');
					}, 500);
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getriskshow(row) {
			this.riskVisible = true;
			this.riskForm.remark = '';
			this.riskId = row.id;
			this.intentionId = row.intention;
		},
		getriskRecord() {
			let data = {
				id: this.riskId,
				label: this.riskForm.label,
				risk: true,
				remark: this.riskForm.remark
			};
			riskRecord(data).then(res => {
				if (res.code == 200) {
					this.riskVisible = false;
					this.tableData.forEach((item, index) => {
						if (item.id == this.riskId) {
							item.btnShow = false;
							this.$refs.multipleTable.setCurrentRow(this.tableData[index]);
						}
					});
					this.treeData.forEach((treeitem, index) => {
						if (this.intentionId == treeitem.vua) {
							treeitem.children.forEach((Citem, index) => {
								if (this.companyId == Citem.vua) {
									Citem.quality = true;
								}
							});
						}
					});
					this.$message.success(res.data);
				} else {
					this.$message.success(res.message);
				}
			});
		},
		getriskRecord2(row) {
			let data = {
				id: row.id,
				risk: false
			};
			riskRecord(data).then(res => {
				if (res.code == 200) {
					this.tableData.forEach((item, index) => {
						if (item.id == row.id) {
							item.btnShow = false;
							this.$refs.multipleTable.setCurrentRow(this.tableData[index]);
						}
					});
					this.riskVisible = false;
					this.treeData.forEach((treeitem, index) => {
						if (row.intention == treeitem.vua) {
							treeitem.children.forEach((Citem, index) => {
								if (this.companyId == Citem.vua) {
									Citem.quality = true;
								}
							});
						}
					});
					this.$message.success(res.data);
				} else {
					this.$message.success(res.message);
				}
			});
		},
		closeDialog() {
			this.riskVisible = false;
		},
		//查看大图
		handlePictureCardPreview(file) {
			if (file) {
				this.dialogImageUrl = file;
				this.addVisible = true;
			}
		}
	}
};
</script>

<style scoped>
.qcontent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.tree_box {
	max-height: 100%;
	position: relative;
	top: 0;
	padding: 10px;
}
.tip-box {
	cursor: pointer;
	position: relative;
	left: 20px;
	top: -10px;
}
.tip-box .el-image {
	display: none;
	width: 300px;
	height: auto;
	z-index: 9999;
	position: absolute;
	right: 0;
	padding: 10px 20px;
	border-radius: 8px;
	background: #000;
	color: #fff;
	opacity: 0.8;
}
.tip-box:hover .el-image {
	display: block;
}
/deep/.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
	background-color: rgba(79, 122, 253, 0.8) !important;
	color: #fff !important;
}
/deep/.el-upload--text {
	width: 100px;
	height: 100px;
	float: left;
	margin: 20px;
}
.zj {
	color: #1afa29;
	font-size: 22px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.zj span {
	font-size: 14px;
	margin-left: 5px;
}
</style>
