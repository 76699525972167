<template>
	<div>
		<div class="container">
			<div>
				<el-input size="small" v-model="query.userPhone" clearable placeholder="请输入账号" class="handle-input mr10" style="width: 160px;"></el-input>
				<el-input
					size="small"
					v-model="query.error"
					min="1"
					oninput="value=value.replace(/[^\d]/g,'')"
					clearable
					placeholder="请输入大于误差值"
					class="handle-input mr10"
					style="width: 160px;"
				></el-input>
				<el-date-picker size="small" type="date" placeholder="选择日期" v-model="query.date1" style="width: 200px;margin-right: 10px;" @change="getTime"></el-date-picker>
				<el-button size="small" type="primary" class="m_l_10" @click="getHMTodayRecord">查询</el-button>
				<el-button size="small" type="primary" class="m_l_10" @click="getdownloadHMTodayRecord">导出</el-button>
				<i class="el-icon-warning-outline tip-box">
					<div>
						<p>1.每日09:10更新 当日08点前所有数据 。</p>
						<p>2.每日11:10更新 当日10点前所有数据。</p>
						<p>3.每日13:10更新 当日12点前所有数据。</p>
						<p>4.每日15:10更新 当日14点前所有数据。</p>
						<p>5.每日17:10更新 当日16点前所有数据。</p>
						<p>6.每日19:10更新 当日18点前所有数据。</p>
						<p>7.每日21:10更新 当日20点前所有数据。</p>
						<p>8.每日23:10更新 当日22点前所有数据。</p>
						<p>由于数据过多，建议更新时间10分钟后查阅数据</p>
					</div>
				</i>
			</div>

			<el-table
				v-loading="loading"
				:data="tableData"
				border
				class="table m_t_20"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="userPhone" label="账号" align="center"></el-table-column>
				<el-table-column prop="hm" label="河马接通次数" align="center"></el-table-column>
				<el-table-column prop="app" label="APP接通次数" align="center"></el-table-column>
				<el-table-column prop="disparity" label="差值" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 500, 1000, 2000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { downloadHMTodayRecord, HMTodayRecord } from '../api/reportNumber.js';

export default {
	name: 'validate',
	data() {
		return {
			query: {
				date1: '',
				error: '',
				userPhone: '',
				pageIndex: 1,
				pageSize: 100
			},
			tableData: [],
			loading: false,
			pageTotal: 0,
			time: ''
		};
	},
	created() {
		this.getDate();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getTime() {
			if (this.query.date1) {
				this.time = this.toDateTimeStr(this.query.date1);
			} else {
				this.time = this.query.date1;
			}
		},
		getHMTodayRecord() {
			let data = {
				time: this.time,
				error: this.query.error,
				userPhone: this.query.userPhone,
				userId: localStorage.getItem('user'),
				pageIndex: this.query.pageIndex,
				pageSize: this.query.pageSize
			};
			HMTodayRecord(data).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		getDate() {
			let now = new Date();
			let year = now.getFullYear(); //得到年份
			let month = now.getMonth(); //得到月份
			let date = now.getDate(); //得到日期
			let hour = ' 00:00:00'; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
			month = month + 1;
			month = month.toString().padStart(2, '0');
			date = date.toString().padStart(2, '0');
			this.query.date1 = `${year}-${month}-${date}`; //
			this.time = `${year}-${month}-${date}`;
			this.getHMTodayRecord();
		},
		toDateTimeStr(nS) {
			let date = new Date(nS);
			let YY = date.getFullYear() + '-';
			let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
			let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
			let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
			let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
			return YY + MM + DD;
		},
		getdownloadHMTodayRecord() {
			let data = {
				time: this.time,
				error: this.query.error,
				userPhone: this.query.userPhone
			};
			downloadHMTodayRecord(data).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let fileName = '今日话单' + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
			});
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getHMTodayRecord();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getHMTodayRecord();
		}
	}
};
</script>

<style scoped>
/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 14px;
}

.mr10 {
	width: 400px;
	margin-right: 10px;
}
.grid-content {
	display: flex;
	align-items: center;
	height: 100px;
}
/deep/ .el-col-16 {
	flex: 0 0 59.9%;
}
.grid-cont-right {
	flex: 1;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #999;
}
.grid-num {
	font-size: 30px;
	font-weight: bold;
}

.grid-con-icon {
	font-size: 50px;
	width: 100px;
	height: 100px;
	text-align: center;
	line-height: 100px;
	color: #fff;
}
.grid-con-1 .grid-con-icon {
	background: linear-gradient(#ffb199, #ff0844);
}
.new {
	color: #b5b4b4;
	font-size: 12px;
}
.grid-con-1 .grid-num {
	color: #4f7afd;
}

.grid-con-2 .grid-con-icon {
	background: linear-gradient(#48c6ef, #6f86d6);
}

.grid-con-2 .grid-num {
	color: #4f7afd;
}

.grid-con-3 .grid-con-icon {
	background: linear-gradient(#e5b2ca, #7028e4);
}

.grid-con-3 .grid-num {
	color: #4f7afd;
}
.grid-con-4 .grid-con-icon {
	background: linear-gradient(#00c6fb, #005bea);
}

.grid-con-4 .grid-num {
	color: #4f7afd;
}
.grid-con-5 .grid-con-icon {
	background: linear-gradient(#89f7fe, #66a6ff);
}

.grid-con-5 .grid-num {
	color: #4f7afd;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 8px 16px;
	font-size: 14px;
	border-radius: 4px;
	margin-left: 10px;
	width: 88px;
	display: inline-block;
}
.tip-box {
	/* float: left; */
	cursor: pointer;
	position: relative;
	left: 20px;
	top: -10px;
}
.tip-box div {
	display: none;
	width: 300px;
	height: auto;
	z-index: 9999;
	position: absolute;
	right: 0;
	padding: 10px 20px;
	border-radius: 8px;
	background: #000;
	color: #fff;
	opacity: 0.8;
}
.tip-box div p {
	line-height: 1.5;
}
.tip-box:hover div {
	display: block;
}
</style>
<style>
.validate .el-upload {
	width: 100% !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
}
.validate .btn {
	background-color: #4f7afd;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	border: none;
	margin-left: 80%;
}
</style>
