<template>
	<div>
		<div class="container">
			<div class="handle-box" v-if="isAdmin">
				<el-button size="small" type="primary" icon="el-icon-add  el-icon--right" class="handle-add mr100" @click="showForm">添加品牌</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="label" label="品牌ID" align="center"></el-table-column>
				<el-table-column prop="value" label="品牌名称" align="center"></el-table-column>

				<el-table-column label="操作" width="180" align="center" v-if="isAdmin">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row.label)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog title="添加品牌" v-model="addVisible" width="500px" @close="closeDialog('form')" style="min-height: 30%;" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="品牌名称" prop="name"><el-input size="small" v-model="form.name"></el-input></el-form-item>
			</el-form>
			<div style="display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px;">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" type="primary" @click="addCardType('form')">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getCardType, addCardType, deleteCardType } from '../api/cardType.js';

export default {
	name: 'agent',
	data() {
		return {
			tableData: [],
			agents: [],
			multipleSelection: [],
			delList: [],
			editVisible: false,
			addVisible: false,
			selectForm: {
				region: 0
			},
			form: {
				name: ''
			},
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			rules: {
				name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }]
			},
			isAdmin: false
		};
	},
	created() {
		let role = localStorage.getItem('ms_role');
		if (role === 'admin') {
			this.isAdmin = true;
		}

		this.getData();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getData() {
			getCardType().then(res => {
				if (res.code === 200) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		showForm() {
			this.addVisible = true;
		},
		addCardType(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					this.form.currentUserId = localStorage.getItem('user');
					addCardType(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('添加成功');
							this.getData();
						}else{
							this.$message.error(res.message);
						}
						this.addVisible = false;
					});
				}
			});
		},

		// // 删除操作
		handleDelete(index, id) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = {
						id: id,
						currentUserId: localStorage.getItem('user')
					};

					deleteCardType(data).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');

							this.tableData.splice(index, 1);
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},

		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		closeDialog(formName) {
			this.addVisible = false;
			(this.form = {
				name: ''
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
	height: 40px;
}
/* 
a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
