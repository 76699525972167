<template>
	<div>
		<div class="container">
			<!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
			<!-- <el-tab-pane label="用户查询" name="single"> -->
			<div class="handle-box">
				<el-input size="small" v-model="phone" clearable maxlength="12" placeholder="号码" style="width: 180px;" class="handle-input mr10"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="queryTelephone">搜索</el-button>
			</div>
			<div class="table-box" v-if="dxtDet.serviceNum">
				<div class="user-info-box" style="width: 500px;">
					<div class="user-info-tit">账户信息</div>
					<div class="info-box">
						<div class="info-name">号码：</div>
						<div class="info">{{ dxtDet.serviceNum }}</div>
					</div>
					<div class="info-box">
						<div class="info-name">网络：</div>
						<div class="info">{{ dxtDet.prodCode }}</div>
					</div>
					<div class="info-box">
						<div class="info-name">套餐名称：</div>
						<div class="info">{{ dxtDet.prodName }}</div>
					</div>
				</div>
				<div class="user-info-box">
					<div class="user-info-tit">账户状态</div>
					<div class="info-box">
						<div class="info-name">用户状态：</div>
						<div class="info">{{ dxtDet.userState }}</div>
					</div>
					<div class="info-box">
						<div class="info-name">停开机状态：</div>
						<div class="info">{{ dxtDet.userStateName }}</div>
					</div>
					<div class="info-box">
						<div class="info-name">激活时间：</div>
						<div class="info">{{ dxtDet.openDate || '未开户' }}</div>
					</div>
					<div class="info-box">
						<div class="info-name"></div>
						<div class="info"></div>
					</div>
				</div>
				<!-- <div class="user-info-box">
					<div class="user-info-tit">话费余量</div>
					<div class="info-box">
						<div class="info-name">当月消费话费：</div>
						<div class="info">{{ dxtDet.BALANCE }}元</div>
					</div>
					<div class="info-box">
						<div class="info-name">欠费金额：</div>
						<div class="info" style="color: #E81A1A;">{{ dxtDet.AMOUNT_OWED }}元</div>
					</div>
					<div class="info-box">
						<div class="info-name">话费余额：</div>
						<div class="info" style="color: #B12E2E;">{{ dxtDet.REAL_BALANCE }}元</div>
					</div>
					<div class="info-box">
						<div class="info-name"></div>
						<div class="info"></div>
					</div>
				</div> -->
			</div>
			<!-- </el-tab-pane>
				<el-tab-pane label="批量查询" name="batch">
					<div class="handle-box">
						<el-button size="small" type="primary" :class="[theme]" @click="getbatchQueryRecord">批量查询</el-button>
						<a :href="`${path}dxtQuery.txt`" class="down" style="margin:10px 10px;" download="批量查询模板.txt" :class="[theme]">
							<i class="el-icon-download"></i>
							下载模板
						</a>
						<el-button size="small" type="primary" :class="[theme]" @click="batchQueryRecords">刷新</el-button>
					</div>
					<el-table
						:data="tableData"
						border
						class="table"
						ref="multipleTable"
						header-cell-class-name="table-header"
						:row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }"
						:header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName"
						@selection-change="handleSelectionChange"
					>
						<el-table-column prop="uid" label="处理批次" align="center" width="350"></el-table-column>
						<el-table-column prop="count" label="总数量" align="center"></el-table-column>
						<el-table-column prop="success" label="成功" align="center"></el-table-column>
						<el-table-column prop="err" label="失败" align="center">
							<template #default="scope">
								<span style="color: #f56c6c;">{{ scope.row.err }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="time" label="时间" align="center"></el-table-column>
						<el-table-column prop="userPhone" label="操作者" align="center"></el-table-column>
						<el-table-column label="操作" width="240" align="center">
							<template #default="scope">
								<a :href="scope.row.url" class="down" download="迪迦用户信息批量查询数据.text" :class="[theme]">
									<i class="el-icon-download"></i>
									下载
								</a>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination
							background
							layout="total, sizes, prev, pager, next, jumper"
							:current-page="batchquery.pageIndex"
							:page-sizes="[10, 30, 50, 100, 300]"
							:page-size="batchquery.pageSize"
							:total="pageTotal"
							@size-change="handleSizeChange"
							@current-change="handlePageChange"
						></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs> -->
			<el-dialog title="批量查询" v-model="addVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="addForm" ref="editForm">
					<el-form-item label="上传文件" prop="">
						<el-upload ref="upload" action="/api/dxt/batchQuery" :headers="token" :limit="1" :on-success="uploadSuccess" :auto-upload="false" :data="uploadData">
							<el-button size="small" type="primary" :class="[theme]" style="margin-top: 50px;">选择文件</el-button>
							<div class="el-upload__tip">只能导入txt文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="submitUpload">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { queryTelephone, batchQueryRecord } from '../api/haihang.js';
export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			phone: '',
			batchquery: {
				pageIndex: 1,
				pageSize: 10,
				userId: localStorage.getItem('user')
			},
			dxtDet: '',
			activeName: 'single',
			uploadData: {
				userId: localStorage.getItem('user')
			},
			addVisible: false,
			tableData: [],
			pageTotal: 0,
			token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
			path: process.env.BASE_URL
		};
	},

	created() {
		// this.phone = localStorage.getItem('userinfophone');
		if (this.phone) {
			this.queryTelephone();
		}
	},

	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		handleClick(tab, event) {
			this.activeName = tab.props.name;
			if (tab.props.name == 'single') {
				// this.queryTelephone();
			} else {
				this.batchQueryRecords();
			}
		},
		queryTelephone() {
			this.dxtDet = '';
			localStorage.setItem('userinfophone', this.phone);
			let data = {
				phone: this.phone
			};
			queryTelephone(data).then(res => {
				if (res.code == 200) {
					this.dxtDet = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		batchQueryRecords() {
			batchQueryRecord(this.batchquery).then(res => {
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		submitUpload() {
			if (this.$refs.upload.uploadFiles[0]) {
				this.loading = true;
				this.$refs.upload.submit();
				this.batchQueryRecords();
			} else {
				this.$message.error('请选择上传文件');
			}
		},
		//导入上传
		uploadSuccess(success) {
			setTimeout(() => {
				this.$refs.upload.clearFiles();
				this.addVisible = false;
			}, 2000);
			if (success.code == 200) {
				this.$message.success('上传成功');
				this.getData();
			} else {
				// this.open(success.message);
				this.$message.error(success.message);
			}
		},
		getbatchQueryRecord() {
			this.addVisible = true;
		},
		closeDialog() {
			this.addVisible = false;
		},
		handlePageChange(val) {
			this.batchquery.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.batchquery.pageSize = val;
			this.getData();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.recharge-box .el-radio__input {
	display: none;
}
.recharge-box .el-radio.is-bordered {
	padding: 8px 14px 0 5px;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 30px;
}

.table-box {
	width: 1400px;
	border: 1px solid #ebeef5;
	display: flex;
	flex-direction: row;
}
.user-info-box {
	/* border: 1px solid #ebeef5; */

	width: 600px;
}
.user-info-tit {
	background: #fafafa;
	color: #000;
	font-weight: 600;
	text-align: center;
	height: 34px;
	line-height: 34px;
	border-right: 1px solid #ebeef5;
	border-bottom: 1px solid #ebeef5;
}
.info-box {
	display: flex;
	flex-direction: row;
	height: 34px;
	line-height: 34px;
	border-bottom: 1px solid #ebeef5;
	border-right: 1px solid #ebeef5;
}
.info-name {
	background: #fafafa;
	border-right: 1px solid #ebeef5;
	width: 120px;
	padding-left: 10px;
	font-weight: 600;
}
.user-info-box .info-box .info {
	padding-left: 10px;
	color: #226003;
}
.down {
	border: 1px solid #dcdfe6;
	background: #4f7afd;
	color: #ffffff;
	transition: 0.1s;
	font-weight: 500;
	padding: 6px 12px;
	font-size: 12px;
	border-radius: 4px;
	display: inline-block;
}
</style>
